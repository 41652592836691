import './bootstrap'
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.performance = true

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

window.vm = new Vue({
    el: '#app',
    components: {
        'add-account': () => import('./components/AddAccount.vue'),
        'edit-account': () => import('./components/EditAccount.vue'),
        'implement-form': () => import('./components/ImplementForm.vue'),
        'capacity-form': () => import('./components/CapacityForm.vue'),
        'media-outlet-list': () => import('./components/MediaOutletList.vue'),
        'media-outlet-form': () => import('./components/MediaOutletForm.vue'),
        'refresh-session': () => import('./components/RefreshSession.vue'),
        'logic-models': () => import('./components/LogicModels.vue'),
        'program-form': () => import('./components/ProgramForm.vue'),
        'cohorts-form': () => import('./components/CohortsForm.vue'),
        'campaigns-form': () => import('./components/CampaignsForm.vue'),
        'activity-count-options': () => import('./components/reports/ActivityCountOptions.vue'),
        'block-grant-options': () => import('./components/reports/BlockGrantOptions.vue'),
        'number-served-options': () => import('./components/reports/NumberServedOptions.vue'),
        'test-form': () => import('./components/TestForm.vue'),
        'add-participant': () => import('./components/AddParticipant.vue'),
        'ad-hoc-options': () => import('./components/reports/AdHocOptions'),
        'cars-options': () => import('./components/reports/CarsOptions'),
        'carisk-options': () => import('./components/reports/CariskOptions'),
        'provider-detail-options': () => import('./components/reports/ProviderDetailOptions'),
        'activity-log-form': () => import('./components/ActivityLogForm'),
        'query-builder-options': () => import('./components/reports/QueryBuilderOptions'),
        'generated-resource-form': () => import('./components/GeneratedResourceForm'),
        'resources-generated-options': () => import('./components/reports/ResourcesGeneratedOptions'),
        'archive-interface': () => import('./components/ArchiveInterface'),
        'rate-creation-form': () => import('./components/RateCreationForm'),

        // PARTIALS
        'geos-form': () => import('./components/partials/GeosForm.vue'),
        'gender-dropdown': () => import('./components/partials/GenderDropdown.vue'),
        'cpwi-program-selector': () => import('./components/partials/CpwiProgramSelector.vue'),
        'cpwi-report-output-form': () => import('./components/partials/CpwiReportOutputForm.vue'),
    },
    methods: {
        deleteMember(memberId) {
            axios.delete('/capacity/members/' + memberId).then(response => {
                window.location.href = '/capacity/members'
            }).catch(error => {
                alert(error.response.data)
            })
        },
        deleteMemberGroup(memberGroupId) {
            axios.delete('/capacity/member-groups/' + memberGroupId).then(response => {
                window.location.href = '/capacity/members'
            }).catch(error => {
                alert(error.response.data)
            })
        },
        deletePartnerGroup(partnerGroupId) {
            axios.delete('/capacity/partner-groups/' + partnerGroupId).then(response => {
                window.location.href = '/capacity/partners'
            }).catch(error => {
                alert(error.response.data)
            })
        },
        deletePartner(partnerId) {
            axios.delete('/capacity/partners/' + partnerId).then(response => {
                window.location.href = '/capacity/partners'
            }).catch(error => {
                alert(error.response.data)
            })

        },
        deleteOrganizationPartner(partnerId) {
            axios.delete('/capacity/partners/organizations/' + partnerId).then(response => {
                window.location.href = '/capacity/partners'
            }).catch(error => {
                alert(error.response.data)
            })
        },
        deleteMeeting(meetingId) {
            axios.delete('/capacity/meetings/' + meetingId).then(response => {
                window.location.href = '/capacity/meetings'
            }).catch(error => {
                alert(error);
            });
        },
        deleteParticipant(participantId) {
            axios.delete('/plan/participants/' + participantId).then(response => {
                window.location.href = '/plan/participants';
            }).catch(error => {
                alert(error);
            });
        },
        submitFilterForm() {
            this.$refs.filterForm.submit()
        }
    }
})
